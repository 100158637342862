import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', loadChildren: () => import('./payhost-card-payment/payhost-card-payment.module').then(m => m.PayhostCardPaymentModule) },
  { path: 'cardpayment', loadChildren: () => import('./payhost-card-payment/payhost-card-payment.module').then(m => m.PayhostCardPaymentModule) },
  { path: 'result', loadChildren: () => import('./result/result.module').then(m => m.ResultModule) },
  { path: 'success', loadChildren: () => import('./success/success.module').then(m => m.SuccessModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
